import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { BlogRecentCard, Highlight, PageLayout, ContainerList, TalkWithExpert } from '../../components'

const DocsIndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "00-hero-security-access-mfa.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      posts: allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: {eq: "blog"},
            tags: {eq: "Security"}
          }
        }, 
        sort: {fields: [frontmatter___order, frontmatter___date], 
        order: [ASC, DESC]},
        limit: 3
      ) {
        edges {
          node {
            id
            timeToRead
            frontmatter {
              header
              title
              description
              date
              relativeDate: date(fromNow: true)
              featuredimage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              featuredpost
              tags
            }
            fields {
              slug
            }
            excerpt(pruneLength: 160)
            html
          }
        }
      }
    }
  `)
  
  return (
    <PageLayout 
      location={location}
      title="Platform Security"
      description="Hippobyte keeps your business data safe with industry-leading security."
      backgroundColor="white"
      pageTheme="dark"
      pageHero={{
        section: {
          margin: { horizontal: "11vw" }
        },
        main: {
          heading: {
            label: "Secure and Reliable Enterprise Data Integration Platform",
          },
          subHeading: {
            label: "As a data company, we understand the necessity and upmost importance of securing your business data. Every product and service is thoughtfully  engineered to protect your data, both in-transit and at-rest.",
          },
          style: { flex: "0 1 45%" }
        },
        aside: {
          content: <Img fluid={data.file.childImageSharp.fluid} />,
          style: { flex: "0 1 55%" }
        }
      }}
    >
      <ContainerList
        round="xsmall"
        fontSize="large"
        color="black"
        basis="1/3"
        header={{
          title: "Hoppobyte Web Console Security",
          subTitle: "Hippobyte employs multiple proactive measures to keep your business data secure."
        }}
        items={[
          {
            title: "Password security",
            description: "Should a bad actor attempt to repeatedly guess your Hippobyte Console password, we lock your account and immediately let you know.",
            image: { 
              src: "TrueFalse",
              position: "top",
              theme: "black",
              size: "58px"
            },
          },
          {
            title: "Two-factor authentication",
            description: "An additional layer of security requiring a trusted device in addition to a password. 2FA is available on Enterprise plans.",
            image: { 
              src: "CircleHalfShade",
              position: "top",
              theme: "black",
              size: "58px"
            },
          },
          {
            title: "Single sign-on",
            description: "Available single sign-on (SSO) for more straightforward and secure means of managing teammate accounts.",
            image: { 
              src: "Key",
              position: "top",
              theme: "black",
              size: "58px"
            },
          },
          {
            title: "Audit Logs",
            description: "Hippobyte Console provides detailed logs of teammate access and changes made on your behalf by our platform and teammates.",
            image: { 
              src: "RotateLeft",
              position: "top",
              theme: "black",
              size: "58px"
            },
          },
          {
            title: "Encryption",
            description: "Connections to Hippobyte's Web Console are encrypted by default using industry-standard cryptographic protocols, currently TLS 1.2.",
            image: { 
              src: "Protect",
              position: "top",
              theme: "black",
              size: "58px"
            },
          },
          {
            title: "Auto-session Timeouts",
            description: "We automatically log you out of the Hippobyte Console, even if you have left your computer unattended.",
            image: { 
              src: "Tasks",
              position: "top",
              theme: "black",
              size: "58px"
            }
          }
        ]}
      />
      <Highlight 
        title="Our Policies"
        description="Keeping your data safe, our business depends on it."
        actions={[
          { 
            title: "Web Console", 
            description: "Description of the particular item being displaysed on screen.",
            markdown: `<ul>
              <li>Hippobyte utilizes HSTS to ensure all communication between the browser and Hippobyte's Web Console is encrypted. Hippobyte maintains an A rating from <a href="https://www.ssllabs.com/ssltest/analyze.html?d=console.hippobyte.com&latest">Qualys SSL Labs</a>.</li>
              <li>Hippobyte's pre-built data connectors use minimum read access permissions to acceess and synchronize requested data. When supported by a data connector, OAuth is utilized to access data sets.</li>
              <li>Hippobyte can only connect to web-based data sources via HTTPS, non-secure connections are not supported. For non web-based data sources, Hippobyte employs one or more of the following: SSH tunnels, SSL/TLS and IP whitelising .</li>
              <li>Hippobyte maintans detailed logs for audit purposes. Logs are accessible for all data connectors via Hippobyte's Web Console.</li>
            </ul>`
          },
          { 
            title: "Data Platform", 
            description: "Description of the particular item being displaysed on screen.",
            markdown: `<ul>
              <li>Hippobyte servers are hosted in AWS (Amazon Web Services) and GCP (Google Cloud Platfowm) both of which provided assurances for their respective physical and virtualized computing environments including SOC 1, 2, and 3, and ISO/IEC 27001.</li>
              <li>Hippobyte operates within a Virtual Private Cloud (VPC), with subnets segregated by security level, and firewalls configured to restrict inbound and outbound network access.</li>
              <li>Hippobyte regularly performs automated vulnerability scans and installs security updates and patches. Hippobyte's services and environments are audited by external security specialists.</li>
            </ul>`
          },
          { 
            title: "Data Policies", 
            description: "Description of the particular item being displaysed on screen.",
            markdown: `<ul>
              <li>Hippobyte maintains policies and procedures to keep customer data safe, and routinely educates employees and contractors on their respective roles and responsibilities. Hippobyte maintains strict control access protocols that are audited regularly.</li>
              <li>Hippobyte classifies customer data and credentials as critical assets, all credentials and data are encrypted at rest and in motion using industry-standard methods.</li>
              <li>Hippobyte maintans documented poilcies and procedures for data security, data privacy and data breach security incidents. Documented policies are availble without our <a href="/legal">legal section</a>.</li>
            </ul>`
          }
        ]}
      />
      <TalkWithExpert 
        pad="xlarge" 
        margin={{ top: "xlarge" }} 
        background="dark-4"
      />
      <BlogRecentCard 
        title="Recent `Security` articles from our blog."
        articles={data.posts.edges}
      />
    </PageLayout>
  )
}

export default DocsIndexPage